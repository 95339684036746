import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import {Background} from "react-imgix";
import {Utils} from "../Utilities";

const NotFoundPage = () => (
   <div className="hero">
      <Background src="https://images.prismic.io/new-line/571adcaa-be04-4c0f-99ee-e6ec7f5074a9_Prof_portfolio_6.jpg?ixlib=react-9.3.1&auto=compress%2Cformat&fit=crop&q=100&rect=0%2C0%2C1920%2C1080&w=1678&h=658&dpr=2" className="h-screen bg-gray" width="1920" height="1080">
         <div className="bg-black bg-opacity-30 h-full flex items-center">
            <div className="container mx-auto px-5">

               <div className="text-3xl md:text-4xl lg:text-6xl text-white">
                  <h1>La pagina che stai cercando <br/>non esiste.</h1>
               </div>
               <div className="btn-with-white-after-parent relative inline-block mt-5">
                  <a className={Utils.prototype.btnClass('my-btn-white-outline') + ' block px-10'} href="/">Torna alla Home</a>
               </div>
            </div>
         </div>
      </Background>
   </div>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
